import React, { useState, useEffect } from 'react';

// NEW COMPONENTS
import ManageOffersTableRow from 'studio/components/ManageOffers/Table/Row';
import ExpandableTableHeaderGraphQL from 'common/components/ExpandableTableHeaderGraphQL';
import Filters from 'studio/components/ManageOffers/Filters/Filters';
import RowMenu from './Table/RowMenu';
import SendDocumentsModal from './SendAdditionalDocuments';
import Notification from '../OfferPage/OfferDrafts/Notification';

// LEGACY COMPONENTS THAT ARE STILL USABLE
import ArchiveOfferModal from 'studio/oldJavascripts/components/Pages/Project/Offers/Modal/ArchiveModal';
import ArchiveRejectedOffersModal from 'studio/oldJavascripts/components/Pages/Project/Offers/Modal/ArchiveRejectedOffersModal';
import RescindOfferModal from 'studio/oldJavascripts/components/Pages/Project/Offers/Modal/RescindModal';
import OfferFreeFormFields from 'studio/oldJavascripts/components/Pages/Project/Offers/OfferFreeFormFields';

// HELPERS
import moment from 'moment';
import QueryRefresher from 'common/components/QueryRefresher';
import Loader from 'common/components/Loader';
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';
import Search from 'common/oldJavascripts/components/Shared/Search';
import FetchMoreOffers from './FetchMoreOffers';
import UsaOnly from 'common/components/UsaOnly';
import Feature from 'common/components/Feature';
import classnames from 'classnames';

// MAPS
import accountCodesMap from './utilities/accountCodesMap';

// MUI
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

// HOC
import withApi from 'common/hoc/withApi';
import { compose } from 'redux';
import withPermissionProtection from 'common/hoc/withPermissionProtection';

// LEGACY LAYOUT COMPONENTS
import Header from 'common/oldJavascripts/components/Base/Header';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import ExpandableTable from 'common/oldJavascripts/components/Base/ExpandableTable';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Box from 'common/oldJavascripts/components/Base/Box';
import SendDocumentsButton from './SendAdditionalDocuments/SendDocumentsButton';

// GRAPHQL
import useManageOffers from './hooks/useManageOffers';

const GraphQLTableHeader = ExpandableTableHeaderGraphQL;
const TableRow = ManageOffersTableRow;

const useStyles = makeStyles(theme => ({
  table: {
    width: '100%',
    tableLayout: 'fixed',
  },
  loader: {
    backgroundColor: 'white',
    width: '100%',
    height: '200px',
  },
  optOutText: {
    marginLeft: '20px',
  },
  recordsText: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.16px',
    color: '#8D8D8D',
  },
  employeeNameHeader: {
    width: '17%',
  },
  departmentHeader: {
    width: '11%',
  },
  jobTitleHeader: {
    width: '12%',
  },
  unionHeader: {
    width: '12%',
  },
  startDateHeader: {
    width: '9%',
  },
  rateHeader: {
    width: '8%',
  },
  allowancesHeader: {
    width: '9%',
  },
  statusHeader: {
    width: '10%',
  },
  iNineHeader: {
    width: '9%',
    textAlign: 'center',
  },
  actionsHeader: {
    width: '4%',
    textAlign: 'left',
    paddingLeft: '0px',
  },
  stickyHeader: {
    position: 'sticky',
    zIndex: 1,
    top: 90,
    display: 'table-row',
  },
  stickyHeaderExtension: {
    top: '190px !important',
  },
  stickyHeaderNarrow: {
    top: 68,
  },
  stickyLayout: {
    position: 'sticky',
    top: '-16px',
    zIndex: 1,
  },
  sendDocumentsButton: {
    padding: '8px 20px !important',
    fontSize: '14px !important',
    '&:hover': {
      backgroundColor: '#1A55FD',
    },
  },
  count: {
    color: '#262626',
    fontSize: 16,
    fontWeight: 600,
    display: 'inline-block',
    marginLeft: 4,
  },
  maxCount: {
    color: '#ED1C23',
    fontSize: 18,
    animationName: '$fade',
    animationDuration: '1s',
    animationDelay: '0s',
    animationIterationCount: 'infinite',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    top: '50%',
  },
  '@keyframes fade': {
    '0%': { opacity: '0' },
    '100%': { opacity: '1' },
  },
  btnOutlined: {
    border: '1px solid #0000FF',
    color: '#0000FF',
    backgroundColor: '#fff',
    marginLeft: '12px !important',
    '&:disabled': {
      backgroundColor: '#9A9FAF',
    },
  },
  countContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0px',
  },
  actionsContainer: {
    position: 'relative',
    marginTop: '8px',
  },
  header: {
    fontSize: '1.6rem',
  },
  headerSingleOffer: {
    fontSize: '1.6rem',
    minHeight: 'auto',
    background: 'red',
  },
}));

const ManageOffers = props => {
  // PROPS
  const {
    routerParams: { projectId, offerId },
    routerQuery: { q: searchTerm = '', status: queryStatus = [] },
    project: projectQuery = {},
    amendRouterQuery: updateQuery,
  } = props;
  const isOfferDetails = parseInt(offerId) > 0;
  const offerDetailsId = isOfferDetails ? parseInt(offerId) : null;

  const classes = useStyles();

  // STATE
  const [expandedPacketFlow, setExpandedPacketFlow] = useState({});
  const [expandedOfferDetails, setExpandedOffersDetails] = useState(
    isOfferDetails ? [offerDetailsId] : [],
  );
  const [expandedOfferStatus, setExpandedOffersStatus] = useState([]);
  const [showAccountCodeModalId, toggleAccountCodeModal] = useState(null);
  const [modalProperties, setModalProperties] = useState({
    showModal: false,
    modalType: 'archive',
    modalOfferId: 0,
    offerStatus: null,
  });
  const [sortAndDirection, setSortAndDirection] = useState({
    direction: 'asc',
    sort: null,
  });
  const [contextMenuAnchor, setContextMenuAnchor] = useState(null);
  const [contextualMenuOffer, setContextualMenuOffer] = useState(null);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const [sendDocumentsModal, setSendDocumentsModal] = useState(false);

  const [message, setMessage] = useState(null);
  const [snackOpen, setSnackOpen] = useState(false);

  // Pulling out some state properties
  const { direction, sort } = sortAndDirection;
  const { showModal, modalType, modalOfferId, offerStatus } = modalProperties;

  // FILTERS MODAL METHODS
  const [selectedFilters, setSelectedFiltersToSubmit] = useState({});
  const [submittedFilters, submitFilters] = useState({});

  // preload status filter from URL QueryParams
  useEffect(() => {
    if ((queryStatus || []).length > 0) {
      let status = queryStatus;
      if (!Array.isArray(status)) {
        status = status.toString().split(',');
      }
      setSelectedFiltersToSubmit(prev => ({ ...prev, status }));
      submitFilters(prev => ({ ...prev, status }));
    }
  }, [queryStatus]);

  // VARIABLES FOR offers hook
  const manageOffersVariables = {
    projectId: parseInt(projectId),
    ...(isOfferDetails
      ? {
          offerIds: [offerDetailsId],
        }
      : {
          offerIds: [],
          keyword: searchTerm,
          after: null,
          sort,
          direction,
          filter: submittedFilters,
          status: 'completed',
        }),
  };

  // GRAPHQL HOOK
  const {
    data: { nodes: offers = [], pageInfo: offersPageInfo = {} } = [],
    loading: offersLoading,
    error: { message: graphQlError = '' } = {},
    refetch: reloadQuery,
    fetchMore: fetchMoreOffers,
    networkStatus,
  } = useManageOffers(manageOffersVariables);

  // LEGACY DATA
  const { data: projectData = {} } = projectQuery || {};
  const {
    allow_end_date: endDateAllowed,
    backdate_days_limit: backdateLimit,
    privileges,
  } = projectData || {};
  const CHECKBOX_LIMIT =
    process.env.REACT_APP_MANAGE_OFFERS_CHECKBOX_LIMIT || 20;

  // CHECKS
  const isLoading = offersLoading && networkStatus !== 3;
  const hasOffers = offers.length > 0 && !isLoading;
  const hirerCanSendDocuments =
    privileges && privileges.includes('can_hirer_send_documents');
  const limitCheck = selectedOffers.length >= CHECKBOX_LIMIT;

  const stickyHeaderClass = classnames(
    classes.stickyHeader,
    selectedOffers.length > 0 ? classes.stickyHeaderExtension : '',
    isOfferDetails ? classes.stickyHeaderNarrow : '',
  );

  // SET SORT AND DIRECTION
  const sortAndDirectionForOffers = (direction, fieldName) => {
    setSortAndDirection({
      direction,
      sort: fieldName,
    });
  };

  const handleQuery = () => {
    if (selectedOffers.length > 0) {
      setSelectedOffers([]);
    }
    return reloadQuery();
  };

  // MODAL METHODS
  const openModal = (type, offerId, offerStatus) => {
    setModalProperties({
      modalType: type,
      modalOfferId: offerId,
      offerStatus,
      showModal: true,
    });
  };

  const modalClose = () => {
    setModalProperties({
      modalType: 'archive',
      modalOfferId: 0,
      offerStatus: null,
      showModal: false,
    });
    reloadQuery();
  };

  const updateQueryParams = (data, options = {}) => updateQuery(data, options);

  const submitAllFilters = () => {
    let container = { ...selectedFilters };
    Object.keys(container).forEach(filters => {
      if (container[filters]?.length === 0) {
        delete container[filters];
      }
    });
    submitFilters(container);
    updateQueryParams({ status: container.status });
  };

  const clearFilters = () => {
    setSelectedFiltersToSubmit({});
    submitFilters({});
    updateQueryParams({}, { override: true });
  };

  // RENDER MODAL
  const renderModal = () => {
    if (showModal) {
      const params = { offerId: modalOfferId };

      if (modalType === 'archive') {
        if (offerStatus === 'rejected' || offerStatus === 'rejected_employee') {
          // render new modal since the graphql mutation archiveRejectedOffers
          // only handles rejected/rejected_employee offers, so we need to keep both for the time being
          return (
            <ArchiveRejectedOffersModal params={params} onClose={modalClose} />
          );
        } else {
          return <ArchiveOfferModal params={params} onClose={modalClose} />;
        }
      } else {
        return <RescindOfferModal params={params} onClose={modalClose} />;
      }
    }
  };

  const renderSendDocumentsModal = () => {
    if (sendDocumentsModal) {
      return (
        <SendDocumentsModal
          offers={selectedOffers}
          projectId={projectId}
          onClose={value => handleSendDocumentsModal(value)}
        />
      );
    }
  };

  // TABLE METHODS
  const renderTable = offers => {
    const rows = offers.map(renderTableRow);
    return (
      <ExpandableTable className={classes.table}>
        <tr className={stickyHeaderClass}>
          <GraphQLTableHeader
            setSorting={sortAndDirectionForOffers}
            headerClasses={classes.employeeNameHeader}
            currentDirection={direction}
            fieldName={'name'}
          >
            Employee
          </GraphQLTableHeader>

          <GraphQLTableHeader
            setSorting={sortAndDirectionForOffers}
            headerClasses={classes.departmentHeader}
            currentDirection={direction}
            fieldName={'department'}
          >
            Department
          </GraphQLTableHeader>

          <GraphQLTableHeader
            setSorting={sortAndDirectionForOffers}
            currentDirection={direction}
            headerClasses={classes.jobTitleHeader}
            fieldName={'job'}
          >
            Job Title/
            <br />
            Acct Code
          </GraphQLTableHeader>

          <GraphQLTableHeader
            setSorting={sortAndDirectionForOffers}
            currentDirection={direction}
            fieldName={'union'}
            headerClasses={classes.unionHeader}
          >
            Union
          </GraphQLTableHeader>

          <GraphQLTableHeader
            setSorting={sortAndDirectionForOffers}
            currentDirection={direction}
            fieldName={'start_date'}
            headerClasses={classes.startDateHeader}
          >
            Start Date
          </GraphQLTableHeader>

          {endDateAllowed && (
            <GraphQLTableHeader
              setSorting={sortAndDirectionForOffers}
              currentDirection={direction}
              fieldName={'end_date'}
              headerClasses={classes.startDateHeader}
            >
              End Date
            </GraphQLTableHeader>
          )}

          <GraphQLTableHeader
            setSorting={sortAndDirectionForOffers}
            currentDirection={direction}
            fieldName={'rate'}
            headerClasses={classes.rateHeader}
          >
            Rate
          </GraphQLTableHeader>

          <GraphQLTableHeader headerClasses={classes.allowancesHeader}>
            Allowances/
            <br />
            Reimbursements
          </GraphQLTableHeader>

          <UsaOnly>
            <GraphQLTableHeader headerClasses={classes.iNineHeader}>
              I-9 Status
            </GraphQLTableHeader>
          </UsaOnly>

          <GraphQLTableHeader
            setSorting={sortAndDirectionForOffers}
            currentDirection={direction}
            fieldName={'status'}
            headerClasses={classes.statusHeader}
          >
            Offer Status
          </GraphQLTableHeader>

          <GraphQLTableHeader headerClasses={classes.actionsHeader}>
            {''}
          </GraphQLTableHeader>
        </tr>

        {rows}
      </ExpandableTable>
    );
  };

  // Open Offer Action Menu
  const openActionMenu = (ev, offer) => {
    setContextMenuAnchor(ev.currentTarget);
    setContextualMenuOffer(offer);
  };

  // Close Offer Action Menu
  const closeActionMenu = () => setContextMenuAnchor(null);

  const handleAlertClose = status => {
    if (status) {
      setSnackOpen(true);
      setMessage('Documents Rescinded Successfully');
      reloadQuery();
    }
  };

  // handle checkbox click
  const handleCheckBoxClick = offer => {
    if (offer) {
      const index = selectedOffers.findIndex(({ id }) => id === offer.id);
      const offerData = { id: offer.id, profile: offer?.projectUser?.profile };
      if (index === -1) {
        // check if limit is reached
        if (limitCheck) {
          return;
        }
        setSelectedOffers([...selectedOffers, offerData]);
      } else {
        const newSelectedOffers = [...selectedOffers];
        newSelectedOffers.splice(index, 1);
        setSelectedOffers(newSelectedOffers);
      }
    }
  };

  const openSendDocumentsModal = () => {
    setSendDocumentsModal(true);
  };

  // handle clear selected list
  const handleClearSelectedList = () => {
    setSelectedOffers([]);
  };

  const handleSendDocumentsModal = value => {
    setSendDocumentsModal(false);
    if (value === 'sent') {
      setSelectedOffers([]);
      setSnackOpen(true);
      setMessage('Sent');
      reloadQuery();
    }
  };

  const handleStatusChange = value => {
    if (value) {
      reloadQuery();
    }
  };

  // RENDER TABLE ROW
  const renderTableRow = offer => {
    const { id, startDate } = offer;
    const offerId = parseInt(id);

    const isDetailsExpanded = expandedOfferDetails?.indexOf(offerId) !== -1;
    const isStatusExpanded = expandedOfferStatus?.indexOf(offerId) !== -1;
    const isPacketExpanded = expandedPacketFlow?.offerId;

    const projectBackDate = backdateLimit;
    const offerStartDate = startDate;
    const isOfferExpired = moment()
      .subtract(projectBackDate, 'days')
      .isAfter(moment(offerStartDate));

    return (
      <TableRow
        key={offerId}
        endDateAllowed={endDateAllowed}
        offer={offer}
        params={{
          offer_id: offerId,
          offerExpired: isOfferExpired,
        }}
        isDetailsExpanded={isDetailsExpanded}
        isStatusExpanded={isStatusExpanded}
        isPacketExpanded={isPacketExpanded}
        onToggleDetails={toggleOfferDetails}
        onToggleStatus={toggleOfferStatus}
        onTogglePacketFlow={togglePacketFlow}
        openAccountCodeModal={openAccountCodeModal}
        expandedPacketFlow={expandedPacketFlow}
        onShowModal={openModal}
        data-test-id={`Offers-row-${offerId}`}
        openActionMenu={openActionMenu}
        onCheckBoxClick={handleCheckBoxClick}
        handleStatusChange={handleStatusChange}
        hirerCanSendDocuments={hirerCanSendDocuments}
        selected={selectedOffers.find(({ id }) => parseInt(id) === offerId)}
        disabled={limitCheck}
        projectData={{ id: projectId, privileges }}
      />
    );
  };

  // FREE FORM FIELDS METHODS
  const openAccountCodeModal = offerId => {
    toggleAccountCodeModal(offerId);
  };

  const closeAccountCodeModal = () => {
    toggleAccountCodeModal(null);
  };

  // TOGGLERS
  const togglePacketFlow = (offerId, packetId) => {
    if (packetId) {
      setExpandedPacketFlow({
        [offerId]: packetId,
      });
    }
  };

  const toggleOfferDetails = offerId => {
    const id = parseInt(offerId);
    const expandedOffers = expandedOfferDetails.slice(0);
    const index = expandedOffers.indexOf(id);

    if (index !== -1) {
      expandedOffers.splice(index, 1);
    } else {
      expandedOffers.push(id);
    }

    setExpandedOffersDetails(expandedOffers);
    setExpandedOffersStatus([]);
  };

  const toggleOfferStatus = offerId => {
    const id = parseInt(offerId);
    const expandedOffers = expandedOfferStatus.slice(0);
    const index = expandedOffers.indexOf(id);

    if (index !== -1) {
      expandedOffers.splice(index, 1);
    } else {
      expandedOffers.push(id);
    }

    setExpandedOffersStatus(expandedOffers);
    setExpandedOffersDetails([]);
  };

  return (
    <LayoutContent>
      <Box>{renderModal()}</Box>
      <Box>{renderSendDocumentsModal()}</Box>
      <Box>
        <div className={classes.stickyLayout}>
          <BoxItem>
            <Header {...(isOfferDetails ? { autoHeight: true } : {})}>
              {isOfferDetails && (
                <Header.Title className={classes.headerSingleOffer}>
                  Offer details
                </Header.Title>
              )}
              {!isOfferDetails && (
                <>
                  <Header.Title className={classes.header}>Offers</Header.Title>
                  <QueryRefresher reloadQuery={() => handleQuery()} />
                  <Header.Nav>
                    <Feature name="OfferFilters">
                      <Filters
                        projectId={projectId}
                        setSelectedFiltersToSubmit={setSelectedFiltersToSubmit}
                        filtersToSubmit={selectedFilters}
                        submitAllFilters={submitAllFilters}
                        clearFilters={clearFilters}
                      />
                    </Feature>
                    <Search />
                  </Header.Nav>
                </>
              )}
              <SendDocumentsButton
                onOpen={openSendDocumentsModal}
                onClear={handleClearSelectedList}
                limit={CHECKBOX_LIMIT}
                offers={selectedOffers}
                classes={classes}
              />
            </Header>
          </BoxItem>
        </div>
        {isLoading && <Loader className={classes.loader} />}
        {hasOffers && renderTable(offers)}
        <RowMenu
          projectId={projectId}
          offer={contextualMenuOffer}
          anchorEl={contextMenuAnchor}
          closeMenu={closeActionMenu}
          onShowModal={openModal}
          privileges={privileges}
          onToggleStatus={toggleOfferStatus}
          alertClose={handleAlertClose}
        />
        {!hasOffers && !isLoading && <Blankslate>No Offers</Blankslate>}
        {graphQlError && (
          <Typography color="error" variant="caption" className={classes.error}>
            {graphQlError}
          </Typography>
        )}
      </Box>

      {!isLoading && (
        <span className={classes.recordsText}>
          Showing {offers.length || 0} of {offersPageInfo?.totalCount} offers
        </span>
      )}

      {offersPageInfo?.hasNextPage && !isLoading && (
        <FetchMoreOffers
          networkStatus={networkStatus}
          loadMoreOffers={fetchMoreOffers}
          searchQuery={searchTerm}
          offersPageInfo={offersPageInfo}
        />
      )}
      {showAccountCodeModalId && (
        <OfferFreeFormFields
          offer={accountCodesMap(offers, showAccountCodeModalId)}
          offerId={parseInt(showAccountCodeModalId)}
          projectId={projectId}
          project={projectData}
          onClose={closeAccountCodeModal}
        />
      )}
      <Notification
        message={message}
        open={snackOpen}
        autoHide={2000}
        setOpen={setSnackOpen}
        severity={'success'}
      />
    </LayoutContent>
  );
};

ManageOffers.queries = {
  project: {
    info: function(_, related) {
      const params = related['/router/params'];

      return {
        id: '/projects/' + params.projectId,
      };
    },
  },
};

export default compose(
  withPermissionProtection(['can_create_offers', 'can_review_offers']),
  withApi,
)(ManageOffers);
