import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { Box, CircularProgress, Typography } from '@mui/material';

import withPermissionProtection from 'common/hoc/withPermissionProtection';
import withApi from 'common/hoc/withApi';
import ErrorAlert from 'common/components/ErrorAlert';
import BoxContainer from 'common/oldJavascripts/components/Base/Box';
import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import Header from 'common/oldJavascripts/components/Base/Header';
import Search from 'common/oldJavascripts/components/Shared/Search';
import QueryRefresher from 'common/components/QueryRefresher';
import FetchMoreApprovals from 'studio/components/Approvals/FetchMoreApprovals';
import Blankslate from 'common/oldJavascripts/components/Base/Blankslate';

import useI9Verifications from './hooks/useI9Verifications';
import I9VerificationsTable from './I9VerificationsTable/I9VerificationsTable';

const PENDING_OFFERS_POLL_INTERVAL = 3000;

const styles = {
  root: {
    flex: 1,
    width: '100%',
    height: '100%',
    padding: '20px',
    overflow: 'auto',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '120px',
    background: '#fff',
  },
  pagination: {},
  paginationText: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.16px',
    color: '#8D8D8D',
  },
};

const I9Verifications = props => {
  const { routerQuery: { q: keyword, sort, direction } = {} } = props;
  const { projectId } = useParams();

  const queryVars = {
    id: parseInt(projectId),
    keyword,
    sort,
    direction,
  };
  const {
    data: project,
    loading: originalLoading,
    error,
    refetch,
    fetchMore,
    networkStatus,
  } = useI9Verifications(queryVars);
  const [pendingOfferIds, setPendingOfferIds] = useState(null);
  const [reloadPending, setReloadPending] = useState(false);

  const { allowEndDate } = project?.offerDefaults || {};
  const { nodes: offers = [], pageInfo } = project?.approvals || {};
  const { hasNextPage, totalCount } = pageInfo || {};
  const hasOffersToVerify = offers?.length > 0;
  const loading = originalLoading && networkStatus !== 3 && !reloadPending;
  let pendingOffersTimer = useRef(null);

  useEffect(() => {
    if (originalLoading || !pendingOfferIds) return;

    const pendingOffers = (offers || []).filter(o => !o.readyToSign);
    const ids = pendingOffers.map(o => o.id);
    if (ids.sort().toString() === pendingOfferIds.sort().toString()) return;

    setPendingOfferIds(ids);
  }, [offers, originalLoading, pendingOfferIds]);

  useEffect(() => {
    if ((pendingOfferIds || []).length === 0) {
      setReloadPending(false);
      if (pendingOffersTimer.current) {
        clearTimeout(pendingOffersTimer.current);
      }
    } else {
      pendingOffersTimer.current = setTimeout(() => {
        setReloadPending(true);
        refetch();
      }, PENDING_OFFERS_POLL_INTERVAL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingOfferIds]);

  return (
    <Box sx={styles.root}>
      <BoxContainer>
        <BoxItem>
          <Header>
            <Header.Title>I-9 Documents Review</Header.Title>
            {!error && (
              <>
                <QueryRefresher reloadQuery={refetch} />
                <Header.Nav>
                  <Search />
                </Header.Nav>
              </>
            )}
          </Header>
        </BoxItem>
        {loading && (
          <Box sx={styles.loading}>
            <CircularProgress />
          </Box>
        )}
        {!loading && error && (
          <BoxItem>
            <ErrorAlert>{error.message}</ErrorAlert>
          </BoxItem>
        )}
        {!loading && !error && (
          <>
            {!hasOffersToVerify && (
              <Blankslate>No I-9 documents found</Blankslate>
            )}
            {hasOffersToVerify && (
              <I9VerificationsTable
                projectId={parseInt(projectId)}
                offers={offers}
                allowEndDate={allowEndDate}
              />
            )}
          </>
        )}
      </BoxContainer>
      {!error && hasOffersToVerify && (
        <Box sx={styles.pagination}>
          {!loading && totalCount && (
            <Typography sx={styles.paginationText}>
              Showing {offers?.length} of {totalCount} offers
            </Typography>
          )}
          {hasNextPage && (
            <FetchMoreApprovals
              loadMoreOffers={fetchMore}
              offersPageInfo={pageInfo}
              searchQuery={queryVars.keyword}
              networkStatus={networkStatus}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default compose(
  withPermissionProtection('can_be_i9_verifier'),
  withApi,
)(I9Verifications);
