import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Tooltip } from '@mui/material';

const styles = {
  button: {
    fontSize: '12px',
    minWidth: '80px',
    padding: '4px 8px',
  },
};

const I9VerificationButton = ({ offer, projectId }) => {
  const { id, readyToSign } = offer || {};

  const button = (
    <Button
      sx={styles.button}
      disabled={!readyToSign}
      data-test-id={`Content-${
        readyToSign ? 'verifyButton' : 'verifyProcessing'
      }-${id}`}
      component={Link}
      to={`/projects/${projectId}/i9-verifications/${id}`}
      variant="contained"
      disableElevation
    >
      {readyToSign && 'Verify'}
      {!readyToSign && 'Processing...'}
    </Button>
  );

  return (
    <>
      {readyToSign && button}
      {!readyToSign && (
        <Tooltip
          disableInteractive
          classes={{
            tooltip: 'offer-processing-tooltip',
          }}
          title="This I-9 is being prepared. It will be ready to verify shortly."
        >
          {button}
        </Tooltip>
      )}
    </>
  );
};

export default I9VerificationButton;
