import React from 'react';
import { withStyles } from '@mui/styles';

import ExpandableTable from 'common/oldJavascripts/components/Base/ExpandableTable';
import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';

import Row from './I9VerificationRow';

const styles = {};

const I9VerificationsTable = ({
  projectId,
  offers,
  classes,
  allowEndDate = false,
}) => (
  <ExpandableTable className={classes.table} data-test-id={`Content-headerRow`}>
    <ExpandableTableRow key="header">
      <ExpandableTableCell
        header={true}
        sort="last_name,first_name,middle_name"
        className={classes.employeeNameHeader}
      >
        Employee
      </ExpandableTableCell>
      <ExpandableTableCell header={true} className={classes.phoneHeader}>
        Phone
      </ExpandableTableCell>
      <ExpandableTableCell
        header={true}
        sort="department"
        className={classes.departmentHeader}
      >
        Department
      </ExpandableTableCell>
      <ExpandableTableCell
        header
        sort="occupation"
        className={classes.jobTitleHeader}
      >
        Job Title/
        <br />
        Acct Code
      </ExpandableTableCell>
      <ExpandableTableCell
        header={true}
        sort="union"
        className={classes.unionHeader}
      >
        Union
      </ExpandableTableCell>
      <ExpandableTableCell
        header={true}
        sort="start_date"
        className={classes.startDateHeader}
      >
        Start Date
      </ExpandableTableCell>
      {allowEndDate && (
        <ExpandableTableCell
          header={true}
          sort="end_date"
          className={classes.startDateHeader}
        >
          End Date
        </ExpandableTableCell>
      )}
      <ExpandableTableCell header={true} className={classes.iNineHeader}>
        I-9 Status
      </ExpandableTableCell>
      <ExpandableTableCell header={true} className={classes.actionHeader}>
        Actions
      </ExpandableTableCell>
    </ExpandableTableRow>

    {offers.map((offer, index) => (
      <Row
        key={`i9verification-row-${index}`}
        projectId={projectId}
        offer={offer}
        allowEndDate={allowEndDate}
      />
    ))}
  </ExpandableTable>
);

export default withStyles(styles)(I9VerificationsTable);
