import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query I9verifications(
    $id: Int!
    $keyword: String
    $offerIds: [Int!]
    $sort: String
    $direction: String
    $after: String
  ) {
    project(id: $id) {
      id
      offerDefaults {
        allowEndDate
      }
      approvals(
        keyword: $keyword
        offerIds: $offerIds
        sort: $sort
        direction: $direction
        after: $after
        i9document: true
      ) {
        nodes {
          id
          status
          readyToSign(i9Document: true)
          badges
          startDate
          endDate
          keepI9
          accountCode
          i9Document
          i9Verified
          i9CreatedDate
          documents
          projectUser {
            id
            profile {
              id
              firstName
              lastName
              email
              phone
              fullName
            }
          }
          termsOfHire {
            union {
              code
              description
              isNonUnion
            }
            occupation {
              code
              description
            }
            department {
              code
              id
              name
            }
          }
        }
        pageInfo {
          totalCount
          hasNextPage
          hasPreviousPage
          endCursor
        }
      }
    }
  }
`;

const useI9Verifications = variables => {
  const { data, ...rest } = useQuery(QUERY, {
    variables,
    skip: variables?.id < 1,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  return { data: data?.project, ...rest };
};

export default useI9Verifications;
