import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'redux';
// HoC
import { withStyles } from '@mui/styles';

// MuiComponents
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
// Components
import CrewList from './CrewList';
import CrewSearch from './CrewSearch';
import SelectedCrewList from './SelectedCrewList';
import CrewFilter from './CrewFilter';
import AddCrewMember from '../../AddCrewMember';
// utilities
import debounce from 'lodash.debounce';
import classNames from 'class-names';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const styles = theme => ({
  root: {
    display: 'grid',
    gridTemplateRows:
      'minmax(50px, max-content) max-content minmax(40px, max-content) max-content minmax(40px, max-content) max-content 1fr',
    gridTemplateColumns: '1fr max-content max-content',
  },
  paperError: {
    boxShadow:
      '0px 1px 5px 0px rgb(251, 11, 11), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
  },
  error: {
    fontSize: '0.9rem',
    gridRow: 2,
    placeSelf: 'center',
  },
  tab: {
    textTransform: 'none',
    fontSize: '1.2rem',
    overflow: 'visible',
  },
  header: {
    gridRow: 1,
    gridColumn: 1,
    placeSelf: 'start',
    paddingLeft: 4,
    paddingTop: 8,
    fontWeight: 'bold',
  },
  sortButton: {
    height: '40px',
    width: '40px',
    paddingTop: 8,
  },
  filterRoot: {
    gridRow: 5,
    gridColumn: '1 / -1',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    maxHeight: 40,
    marginBottom: 4,
  },
  tabs: {
    gridRow: 3,
    gridColumn: '1 / -1',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  content: {
    gridRow: '4 / -1',
    gridColumn: '1 / -1',
  },
  divider: {
    gridRow: 6,
    gridColumn: '1 / -1',
  },
  sortIconLabel: {
    height: 16,
    width: 16,
  },
  searchContent: {
    gridRow: 7,
    gridColumn: '1 / -1',
    marginTop: 8,
  },
  badge: {
    top: 10,
    right: -12,
  },
  addCrewButton: {
    gridRow: 1,
    gridColumn: 2,
    placeSelf: 'center',
  },
});

const updateCrewSearch = debounce((update, value) => update(value), 500);

const CrewMembers = props => {
  const {
    data: crewMembersData,
    formData = {},
    hasOfferBeenValidated,
    onChange,
    classes = {},
    onFilter = () => {},
    refetch = () => {},
  } = props;
  const projectId = props?.match?.params?.projectId;
  const isError =
    (hasOfferBeenValidated && Object.keys(formData).length === 0) ||
    (hasOfferBeenValidated &&
      Object.values(formData).every(crewMember => crewMember === undefined)); // check if all crew members are undefined (no crew members selected)

  const [tab, setTab] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [graphqlSearch, setGraphqlSearch] = useState('');
  const [filters, setFilters] = useState({
    withOffers: true,
    withoutOffers: true,
    sort: null,
    sortField: null,
  });
  const [openModal, setOpenModal] = useState(false);
  const selectedCrewMembers = Object.values(formData)
    .filter(crewMember => crewMember)
    .map(node => ({
      node,
    }));

  useEffect(() => {
    // open modal if search param is present
    const addCrew = props?.location?.search;
    if (addCrew === '?addCrew=true') {
      setOpenModal(true);
    }
  }, [props]);

  const searchOnChange = ({ target: { value } = {} } = {}) => {
    setSearchText(value);
    updateCrewSearch(value => {
      onFilter({ search: value });
      setGraphqlSearch(value);
    }, value);
  };
  const onChangeFilters = update => {
    onFilter(update);
    setFilters({ ...filters, ...update });
  };

  const onChangeTab = (_, value) => {
    setTab(value);
    setSearchText('');
  };

  const handleCreateCrew = () => {
    // add search param to url to open modal
    props.history.push({
      search: 'addCrew=true',
    });
    setOpenModal(true);
  };

  const handleDialogClose = () => {
    // remove search param from url
    props.history.push({
      search: '',
    });
    setOpenModal(false);
  };

  const handleCloseModal = crewData => {
    if (crewData?.email) {
      // refetch crew list to get the updated crew member
      // find with email and set it as the selected crew member
      refetch()
        .then(res => {
          const { project: { crew: { edges = [] } = {} } = {} } = res?.data;
          const recentCrew = edges.find(
            crew => crew?.node?.profile?.email === crewData?.email,
          );
          if (recentCrew?.node) onChange(recentCrew?.node);
        })
        .catch(err => console.warn(err?.message));
    }
    // remove search param from url
    props.history.push({
      search: '',
    });
    setOpenModal(false);
  };

  const clearGraphqlSearch = () => setGraphqlSearch('');

  const sortCrewMembers = sort => {
    const sortFilters = {
      sort: sort === 'created_at' ? 'asc' : sort,
      sortField: sort === 'created_at' ? '' : 'name',
    };
    setFilters({
      ...filters,
      ...sortFilters,
    });
    onFilter(sortFilters);
  };

  const isSelected = id => formData[id] !== undefined;

  const rootClass = classNames(classes.root, {
    [classes.paperError]: isError,
  });

  return (
    <Paper className={rootClass}>
      <Typography className={classes.header} variant="h5">
        Crew Members
      </Typography>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => handleCreateCrew()}
        className={classes.addCrewButton}
        startIcon={<AddIcon />}
      >
        Add Crew
      </Button>
      {isError && (
        <Typography color="error" variant="caption" className={classes.error}>
          Please select at least one crew member for this offer
        </Typography>
      )}
      <Tabs
        value={tab}
        onChange={onChangeTab}
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabs}
      >
        <Tab
          label="Crew"
          classes={{
            root: classes.tab,
          }}
          data-test-id="CrewMembers-crewTab"
        />
        <Tab
          label={
            <Badge
              classes={{ badge: classes.badge }}
              color="primary"
              badgeContent={selectedCrewMembers.length}
            >
              Selected
            </Badge>
          }
          classes={{
            root: classes.tab,
          }}
          data-test-id="CrewMembers-selectedTab"
        />
      </Tabs>

      {tab === 0 && (
        <React.Fragment>
          <CrewSearch
            onChange={searchOnChange}
            value={searchText}
            classes={{ root: classes.search }}
          />
          {tab === 0 && (
            <CrewFilter
              filters={filters}
              onChange={onChangeFilters}
              sortCrewMembers={sortCrewMembers}
              classes={{
                root: classes.filterRoot,
                button: classes.sortButton,
              }}
            />
          )}
          <Divider className={classes.divider} />
          <CrewList
            data={crewMembersData}
            search={graphqlSearch}
            filters={filters}
            onChange={onChange}
            isSelected={isSelected}
            classes={{
              root: classes.searchContent,
            }}
          />
        </React.Fragment>
      )}
      {tab === 1 && (
        <SelectedCrewList
          onChange={onChange}
          isSelected={isSelected}
          crew={selectedCrewMembers}
          graphqlSearch={graphqlSearch}
          clearGraphqlSearch={clearGraphqlSearch}
          classes={{ root: classes.content }}
        />
      )}
      <Dialog
        open={openModal}
        onClose={handleDialogClose}
        disableEscapeKeyDown={true}
      >
        <AddCrewMember
          modal={true}
          onClose={handleCloseModal}
          projectId={projectId}
          refetchCrewList={refetch}
        />
      </Dialog>
    </Paper>
  );
};

export default compose(withRouter, withStyles(styles))(CrewMembers);
