import React from 'react';
import { withStyles } from '@mui/styles';
import classNames from 'class-names';
import Icon from './Icon';

const styles = {
  noMargin: { margin: '0px' },
  noMarginBottom: { marginBottom: '0px' },
  hasSidebar: {
    paddingLeft: '66px',
    '&.header-title': { margin: '0' },
  },
  header: {
    zoom: '1',
    minHeight: 75,
  },
  headerBadge: {
    WebkitBorderRadius: '30px',
    borderRadius: '30px',
    fontSize: '2em',
    lineHeight: '2.11538462',
    background: '#979ca5',
    color: '#e6e7ea',
    display: 'block',
    margin: '40px auto 26px auto',
    textAlign: 'center',
    width: '55px',
    height: '55px',
  },
  center: { width: '100%', textAlign: 'center' },
  title: {
    fontSize: '1.84615385em',
    lineHeight: '1.66666667',
    clear: 'left',
    fontWeight: '700',
    letterSpacing: '-0.01em',
  },
  float: {
    float: 'left',
  },
  primary: {
    fontWeight: '400',
    fontSize: '1.9em',
    margin: '18px 20px',
  },
  secondary: {
    fontSize: '1.23076923em',
    lineHeight: '1.25',
    margin: '15px 0',
  },
  tertiary: {
    lineHeight: '1.53846154',
    fontSize: '1.1em',
    textTransform: 'uppercase',
  },
  quarternary: {
    lineHeight: '1.06666667',
    fontSize: '1.1em',
    margin: '5px 0',
  },
  quinary: {
    fontSize: '1em',
    lineHeight: '1.23076923',
    fontWeight: '700',
    margin: '5px 0',
    color: '#373737',
  },
  subtitle: {
    fontSize: '1em',
    lineHeight: '1.15384615',
    margin: '5px 0 15px 0',
    color: '#979ca5',
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  alternative: { color: '#3c698d' },
  blue: { color: '#0000ff' },
  compact: { lineHeight: '0.91666667' },
  flex: {
    display: [
      '-webkit-box',
      '-moz-box',
      'box',
      '-webkit-flex',
      '-moz-flex',
      '-ms-flex',
      'flex',
    ],
    WebkitBoxOrient: 'inherit',
    MozBoxOrient: 'inherit',
    msBoxOrient: 'inherit',
    boxOrient: 'inherit',
    WebkitFlexDirection: 'row',
    MozFlexDirection: 'row',
    msFlexDirection: 'row',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  nav: { float: 'right' },
  fieldRequired: {
    color: '#ea5a5a',
    display: 'inline-block',
    fontWeight: '700',
    marginLeft: '1px',
  },
  titleIcon: {
    fontSize: '1em',
    color: '#b1b7be',
    marginRight: '10px',
  },
  titleNote: {
    lineHeight: '3.15384615',
    float: 'left',
    margin: '7px 0 7px 7px',
  },
  titleNoteSecondary: {
    fontSize: '1.07692308em',
    lineHeight: '2.57142857',
    fontWeight: '700',
    float: 'left',
    margin: '0px 0px 0px 7px',
  },
  padding: {
    padding: 20,
  },
  smallFont: {
    fontSize: '11px',
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  autoHeight: {
    minHeight: 'auto',
  },
};

const Header = props => {
  const {
    classes,
    hasSidebar,
    children,
    padding,
    alignCenter,
    className,
    autoHeight,
  } = props;
  const rootClass = classNames(classes.header, className, {
    [classes.hasSidebar]: hasSidebar,
    [classes.padding]: padding,
    [classes.alignCenter]: alignCenter,
    [classes.autoHeight]: autoHeight,
  });
  return <div className={rootClass}>{children}</div>;
};

const Badge = props => {
  const { classes, icon, label } = props;
  return (
    <div className={classes.headerBadge}>
      <Icon name={icon} label={label} />
    </div>
  );
};

const Title = props => {
  const {
    children,
    classes,
    nomargin,
    center,
    alternative,
    blue,
    compact,
    flex,
    subtitle,
    secondary,
    primary,
    tertiary,
    quarternary,
    quinary,
    required,
    float = true,
  } = props;

  const rootClass = classNames([classes.title], {
    [classes.noMarginBottom]: nomargin === 'bottom',
    [classes.noMargin]: nomargin === true,
    [classes.center]: center,
    [classes.alternative]: alternative,
    [classes.blue]: blue,
    [classes.compact]: compact,
    [classes.flex]: flex,
    [classes.subtitle]: subtitle,
    [classes.secondary]: secondary,
    [classes.primary]: primary,
    [classes.tertiary]: tertiary,
    [classes.quaternary]: quarternary,
    [classes.quinary]: quinary,
    [classes.float]: float,
  });

  return (
    <div className={rootClass} data-test-id={props['data-test-id']}>
      {children}
      {required === true && (
        <span>
          <span className={classes.fieldRequired}>*</span>
        </span>
      )}
    </div>
  );
};

const Nav = props => {
  const { classes, children } = props;
  return <div className={classes.nav}>{children}</div>;
};

const TitleIcon = props => {
  const { classes, icon, label } = props;
  return <Icon className={classes.titleIcon} name={icon} label={label} />;
};

const TitleNote = props => {
  const { children, classes, secondary } = props;
  const className = classNames([classes.titleNote], {
    [classes.titleNoteSecondary]: secondary,
  });
  return <span className={className}>{children}</span>;
};

Header.TitleNote = withStyles(styles)(TitleNote);
Header.Title = withStyles(styles)(Title);
Header.Nav = withStyles(styles)(Nav);
Header.TitleIcon = withStyles(styles)(TitleIcon);
Header.Badge = withStyles(styles)(Badge);

export default withStyles(styles)(Header);
