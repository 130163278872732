import React, { Component } from 'react';

// MaterialUI Components
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip from '@mui/material/Tooltip';
// Utilities
import classNames from 'class-names';
// HoC
import { withStyles } from '@mui/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
// Actions
import { pushNotification } from 'common/store/actions/snackbarNotifications';
// Constants
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';

const MAX_FAILURE_COUNT = 2;

const styles = theme => ({
  _refreshButton: {
    marginTop: '-6px',
  },
  _refreshIcon: {
    color: '#0000FF',
    fontSize: '28px !important',
  },
  refreshing: {
    animation: 'rotate 1.8s linear infinite',
  },
  '@keyframes rotate': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(359deg)',
    },
  },
});

/*
Query Refresher is a small component that adds an Icon button which allows users to refresh a list.
This was designed for use for the offer list table, but can really work on any query from withApi.
It relies on the reload function that gets passed down to the query prop.

Visually This is a small addition using the Icon button from material ui with the refresh material icon.
When the button is clicked the icon has a rotation animation to indicate that the query is being refreshed.

Props:
  classes:            <Object> -  Prop that gets passed in from withStyles. When using this component
                                  you can pass in an object like:
                                    classes={{
                                      icon: classes.pink,
                                      button: classes.big,
                                    }}
                                  And the classes will get joined with the native styles.

  tooltipPlacement:   <String> -  Optional prop that can be used to change the placement of tooltip.

  tooltipTitle:       <String> -  Optional prop that can be used to pass in a different
                                  tooltip in case the query is not represented by a list.

  failureSnackbarMessage: <String> - Optional prop that can be used to override our default failure message.

  reloadQuery:          <Function> - Only required prop.  This is the reload function that gets passed down from
                                      withApi for a query. Must support the success, failure callbacks.
*/
class QueryRefresher extends Component {
  static defaultProps = {
    failureSnackbarMessage:
      'We ran into an issue refreshing this list.  Please try again, or contact customer support if the issue persists.',
    tooltipPlacement: 'bottom',
    tooltipTitle: 'Refresh List',
  };

  state = {
    isReloading: false,
  };

  failureSnackbar = {
    message: this.props.failureSnackbarMessage,
    variant: SnackbarVariants.ERROR,
  };

  success = () => this.setState({ isReloading: false });

  failure = (_, attempt = 1) => {
    const { reloadQuery: upstreamReloadQuery, pushNotification } = this.props;
    this.setState({ isReloading: false });
    if (attempt >= MAX_FAILURE_COUNT) {
      pushNotification(this.failureSnackbar);
      return;
    }
    this.setState({ isReloading: true }, () =>
      upstreamReloadQuery(this.success, () => this.failure('', attempt + 1)),
    );
  };

  reloadQuery = () => {
    const { reloadQuery: upstreamReloadQuery, pushNotification } = this.props;
    try {
      upstreamReloadQuery()
        .then(this.success)
        .catch(this.failure);
    } catch (e) {
      this.setState({ isReloading: false });
      pushNotification(this.failureSnackbar);
    }
  };

  onClick = () => this.setState({ isReloading: true }, this.reloadQuery);

  render() {
    const { tooltipTitle, tooltipPlacement, classes = {} } = this.props;
    const { isReloading } = this.state;

    const refreshIconClasses = classNames(
      {
        [classes.refreshing]: isReloading,
      },
      classes._refreshIcon,
      classes.icon,
    );

    const refreshButtonClasses = classNames(
      classes._refreshButton,
      classes.button,
    );

    return (
      <Tooltip
        disableInteractive
        title={tooltipTitle}
        enterDelay={250}
        placement={tooltipPlacement}
      >
        <IconButton
          className={refreshButtonClasses}
          disabled={isReloading}
          onClick={this.onClick}
          data-test-id="QueryRefresher-button"
        >
          <RefreshIcon className={refreshIconClasses} />
        </IconButton>
      </Tooltip>
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  pushNotification,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(QueryRefresher);
