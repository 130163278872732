import React from 'react';
import { withRouter } from 'react-router';
import qs from 'qs';
import history from 'common/constants/config/history';

const processUrlValue = (value, defaultValue = null) => {
  if (value == null || value.trim() === '') return defaultValue;
  if (!isNaN(value)) return parseFloat(value);
  if (['true', 'false'].includes(value.toLowerCase()))
    return value.toLowerCase() === 'true';
  return value.trim();
};

const withRouteHelpers = InnerComponent =>
  withRouter(props => {
    const { match = {}, location = {} } = props;
    const { params: reactRouterParams } = match || {};
    const { hash, search: reactRouterSearch, pathname: routerPath } =
      location || {};
    const routerQuery = qs.parse(reactRouterSearch, {
      ignoreQueryPrefix: true,
    });
    const routerParams = { ...reactRouterParams };
    const routerHash = hash.slice(1);

    const getRouterParam = (key, defaultValue) =>
      processUrlValue(routerParams[key], defaultValue);

    const getRouterQueryParam = (key, defaultValue) =>
      processUrlValue(routerQuery[key], defaultValue);

    const getRouterHashParam = defaultValue =>
      processUrlValue(routerHash, defaultValue);

    // Takes a search object which it merges into the existing search params.
    const amendRouterQuery = (search, options = {}) => {
      const { override = false } = options || {};
      const existingSearch = qs.parse(history.location.search, {
        ignoreQueryPrefix: true,
      });
      const newSearch = override ? search : { ...existingSearch, ...search };
      history.push({ search: qs.stringify(newSearch) });
      return newSearch;
    };

    const amendRouterHash = hash =>
      history.push({ search: reactRouterSearch, hash });

    return (
      <InnerComponent
        amendRouterQuery={amendRouterQuery}
        amendRouterHash={amendRouterHash}
        routerParams={routerParams}
        routerQuery={routerQuery}
        getRouterParam={getRouterParam}
        getRouterQueryParam={getRouterQueryParam}
        getRouterHashParam={getRouterHashParam}
        routerPath={routerPath}
        routerHash={routerHash}
        // TODO Add more named param helpers
        projectId={getRouterParam('projectId')}
        projectTemplateId={getRouterParam('projectTemplateId')}
        {...props}
      />
    );
  });

export default withRouteHelpers;
