import React, { useState } from 'react';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';

import ajax from 'common/utilities/ajax.js';
import processDocumentRequests from 'common/utilities/processDocumentRequests';

import ErrorAlert from 'common/components/ErrorAlert';
import RejectI9 from 'studio/oldJavascripts/components/Pages/Project/RejectI9';
import DocumentInteractionController from 'studio/oldJavascripts/components/Shared/DocumentInteractionController';

import useI9Verifications from './hooks/useI9Verifications';

const styles = {
  root: {
    width: '100%',
    height: '100%',
    padding: '20px',
    overflow: 'auto',
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const I9Verification = () => {
  const { projectId, offerId } = useParams();
  const [rejectDocumentId, setRejectDocumentId] = useState(null);
  const [
    documentsSuccessfullySubmitted,
    setDocumentsSuccessfullySubmitted,
  ] = useState([]);
  const [errors, setErrors] = useState([]);
  const history = useHistory();

  const queryVars = {
    id: parseInt(projectId),
    offerIds: [parseInt(offerId)],
  };
  const { data: project, loading, error } = useI9Verifications(queryVars);
  const { nodes: offers } = project?.approvals || {};
  const offer = offers?.[0] || {};
  const validOffer = parseInt(offer.id) === parseInt(offerId);
  const { projectUser: { profile = {} } = {} } = offer || {};

  const goBackToList = () =>
    history.push(`/projects/${projectId}/i9-verifications`);

  const filter = doc => {
    const i9Tags = ['i9', 'i-9'];
    const { categories = [] } = doc;
    for (let i = 0; i < categories.length; i += 1) {
      if (i9Tags.includes(categories[i].name.toLowerCase())) return true;
    }
    return false;
  };

  const reject = documentId => {
    if (documentId) {
      setRejectDocumentId(documentId);
    } else {
      goBackToList();
    }
  };

  const rejectI9 = value => {
    setRejectDocumentId(null);
    if (value) {
      setErrors([]);
      goBackToList();
    }
  };

  const save = (docs, isAutosave) => {
    // Sometimes autosave is triggered after the offer is closed in which case
    // we should just ignore it.
    if (isAutosave && !offerId) return;

    const documentRequests = Object.keys(docs)
      .filter(d => docs[d].ready && !documentsSuccessfullySubmitted.includes(d))
      .map(d => {
        const fields = Object.keys(docs[d].fields).map(fieldId => ({
          id: docs[d].fields[fieldId].id,
          value: docs[d].fields[fieldId].value,
        }));
        const { crew_acknowledged, id } = docs[d];
        const postUrl = `/v2/offers/${offerId}/documents/${id}/save-draft`;
        const promise = ajax.post(
          postUrl,
          { fields, crew_acknowledged },
          { json: true },
        );
        return { promise, documentId: id };
      });

    // If this is an autosave don't reject errors - we don't want to pop up
    // mysterious error messages while the user is typing.
    return processDocumentRequests(documentRequests, !isAutosave);
  };

  const accept = (docs, offerId, documents) => {
    const documentRequests = Object.keys(docs)
      .filter(d => !documentsSuccessfullySubmitted.includes(d))
      .map(d => {
        const fields = Object.keys(docs[d].fields).map(fieldId => ({
          id: docs[d].fields[fieldId].id,
          value: docs[d].fields[fieldId].value,
        }));
        const postUrl = `/v2/offers/${offerId}/documents/${d}`;
        const promise = ajax
          .post(postUrl, { fields }, { json: true })
          .then(() => setDocumentsSuccessfullySubmitted(prev => [...prev, d]));
        return { promise, documentId: d };
      });
    return processDocumentRequests(documentRequests).then(goBackToList);
  };

  return (
    <Box sx={{ ...styles.root, ...(loading ? styles.loading : {}) }}>
      {loading && <CircularProgress />}
      {!loading && error && <ErrorAlert title={error.message} />}
      {!loading && !error && !validOffer && (
        <Redirect to={`/projects/${projectId}/i9-verifications`} />
      )}
      {!loading && !error && validOffer && (
        <>
          {Boolean(rejectDocumentId) && (
            <RejectI9
              offerId={offerId}
              documentId={rejectDocumentId}
              onClose={value => rejectI9(value)}
              employee={profile}
            />
          )}
          <DocumentInteractionController
            buttonText={{ accept: 'Verify I-9', reject: 'Cancel' }}
            goBackLabel={'Back to I-9 Reviews'}
            errors={errors}
            offerId={offerId}
            type="verifier"
            offer={{
              ...offer,
              first_name: profile.firstName,
              last_name: profile.lastName,
              start_date: offer.startDate,
            }}
            filter={filter}
            handleOfferAccept={accept}
            handleOfferReject={reject}
            handleEndDocumentInteraction={goBackToList}
            handleSave={save}
          />
        </>
      )}
    </Box>
  );
};

export default I9Verification;
