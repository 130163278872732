import React from 'react';
import { Typography, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import { KeyboardArrowLeft } from '@mui/icons-material';

const styles = theme => ({
  root: {
    background: '#dadada',
    display: 'grid',
    gridTemplateColumns: '25% 50% 10% 15%',
    gridTemplateRows: '100%',
    gridTemplateAreas: `"startDate recipientName .  goBack"`,
  },
  goBack: {
    gridArea: 'goBack',
    color: '#000 !important',
  },
  recipientName: {
    fontSize: '1.1rem',
    placeSelf: 'center',
    gridArea: 'recipientName',
  },
  startDate: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    placeSelf: 'center',
    gridArea: 'startDate',
  },
});

const OfferInfoBar = props => {
  const {
    offer = {},
    goBackLabel = null,
    onGoBackClick,
    classes,
    className,
  } = props;
  const { first_name, last_name, start_date } = offer;
  return (
    <div
      className={classNames(classes.root, className)}
      data-test-id="OfferInfoBar-root"
    >
      <Button
        className={classes.goBack}
        onClick={onGoBackClick}
        data-test-id="OfferInfoBar-goBack"
      >
        <KeyboardArrowLeft />
        {goBackLabel || 'Back to Approvals'}
      </Button>
      <Typography
        variant="h6"
        className={classes.recipientName}
        data-test-id="OfferInfoBar-recipientName"
      >{`Reviewing Offer for: ${first_name} ${last_name}`}</Typography>
      <Typography
        variant="h6"
        className={classes.startDate}
        data-test-id="OfferInfoBar-startDate"
      >{`Start Date: ${start_date}`}</Typography>
    </div>
  );
};

export default withStyles(styles)(OfferInfoBar);
