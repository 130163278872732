import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Layouts
import ProjectLayout from 'common/oldJavascripts/components/Pages/Layouts/Project';

// components for Pendo
import Feature from 'common/components/Feature';
import PendoController from 'common/components/PendoController';

// Components
// Implementation Admins
import UserList from 'admin/oldJavascripts/components/Pages/Project/Users';
import UserEdit from 'admin/oldJavascripts/components/Pages/Project/User/Edit';
import Invitations from 'admin/oldJavascripts/components/Pages/Project/Invitations';
import NewInvitation from 'admin/oldJavascripts/components/Pages/Project/Invitations/New';

// Studio Admins
import ManageOffers from 'studio/components/ManageOffers';
import Approvals from 'studio/components/Approvals';
import ApprovalsSigning from 'studio/components/Approvals/DocumentSigning';
import Reviews from 'studio/oldJavascripts/components/Pages/Project/Reviews';
import DeleteReview from 'studio/oldJavascripts/components/Pages/Project/Reviews/Delete';
import {
  I9Verifications,
  I9Verification,
} from 'studio/components/I9Verifications';
import OfferDocuments from 'studio/components/OfferDocuments/List';
import OffersDocumentsDetails from 'studio/components/OfferDocuments/Details';
import DownloadAllDocuments from 'studio/components/DownloadAllDocuments';

export default props => {
  return (
    <ProjectLayout>
      <Switch>
        <Route
          exact
          path="/projects/:projectId/files/:fileId"
          component={DownloadAllDocuments}
        />
        <Route
          exact
          path="/projects/:projectId/invitations"
          component={Invitations}
        />
        <Route
          exact
          path="/projects/:projectId/invitations/new"
          component={NewInvitation}
        />
        <Route exact path="/projects/:projectId/users" component={UserList} />
        <Route
          exact
          path="/projects/:projectId/users/:userId/edit"
          component={UserEdit}
        />
        <Route
          exact
          path="/projects/:projectId/offers/:offerId/details"
          component={ManageOffers}
        />
        <Route
          exact
          path="/projects/:projectId/offers"
          component={ManageOffers}
        />
        <Route
          exact
          path="/projects/:projectId/approvals"
          component={Approvals}
        />
        <Route
          exact
          path="/projects/:projectId/approvals/:offerId/document-signing"
          component={ApprovalsSigning}
        />
        <Route exact path="/projects/:projectId/reviews" component={Reviews} />
        <Route
          exact
          path="/projects/:projectId/reviews/:reviewId/delete"
          component={DeleteReview}
        />
        <Route
          exact
          path="/projects/:projectId/i9-verifications"
          component={I9Verifications}
        />
        <Route
          exact
          path="/projects/:projectId/i9-verifications/:offerId"
          component={I9Verification}
        />
        <Route
          exact
          path="/projects/:projectId/offer-documents"
          component={OfferDocuments}
        />
        <Route
          exact
          path="/projects/:projectId/offer-documents/:offerId/details"
          component={OffersDocumentsDetails}
        />
      </Switch>
      <Feature name="pendoIntegration">
        <PendoController projectId={props?.match?.params?.projectId} />
      </Feature>
    </ProjectLayout>
  );
};
