import React, { useState, useEffect } from 'react';

// MATERIAL
import { Badge, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import FilterListIcon from '@mui/icons-material/FilterList';

// COMPONENTS
import FiltersModal from './FiltersModal';

const useStyles = makeStyles(theme => ({
  container: {
    marginRight: '40px',
  },
  filtersBadge: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1px 6px',
    position: 'absolute',
    width: '26px',
    height: '18px',
    left: '1189px',
    top: '246px',
    background: '#BECFFE',
    borderRadius: '2px',
  },
}));

const StyledButton = styled(Button)({
  background: '#FFFFFF',
  color: '#0000FF',
  padding: '10px 16px',
  border: '1px solid #0000FF',
  boxSizing: 'border-box',
  boxShadow:
    '0px 1px 1px rgba(82, 82, 82, 0.1), 0px 2px 4px rgba(82, 82, 82, 0.1), 0px 1px 5px rgba(82, 82, 82, 0.08)',
  borderRadius: '2px',
});

const Filters = props => {
  const classes = useStyles();

  const {
    projectId,
    setSelectedFiltersToSubmit,
    filtersToSubmit,
    submitAllFilters,
    clearFilters,
  } = props;

  const [isOpen, setModalState] = useState(false);

  // Toggle Modal
  const toggleModal = () => {
    setModalState(!isOpen);
  };

  // Keep Count of How many filters have been selected
  const [numberOfFiltersSelected, getFiltersSelected] = useState(0);

  // Change Filters Values Selected
  const [filterValues, setFilterValues] = useState({});

  // Update values for filters selection
  const updateFiltersSelection = (name, values) => {
    const updatedFiltersSelection = [...values];

    // Needed since filters can either have a numerical id like '123321' or string id like "status"
    // Checked with the back end and statuses dont have an id, just a string identifier
    const numericalRegex = /^[0-9\b]+$/;

    const arrayOfIds = updatedFiltersSelection.map(ids =>
      numericalRegex.test(ids.id) ? parseInt(ids.id) : ids.id,
    );

    setFilterValues({
      ...filterValues,
      [name]: updatedFiltersSelection,
    });

    // these are just ids needed to submit filters
    setSelectedFiltersToSubmit({
      ...filtersToSubmit,
      [name]: arrayOfIds,
    });
  };

  const updateFiltersDateSelection = (name, value) => {
    setFilterValues({
      ...filterValues,
      [name]: value,
    });
    // these are just ids needed to submit filters
    setSelectedFiltersToSubmit({
      ...filtersToSubmit,
      [name]: value,
    });
  };

  useEffect(() => {
    let count = 0;

    // dates changed from moment objects to strings
    // changed logic here so selected filter count is shown properly
    Object.values(filterValues || []).forEach(filters => {
      // handles nulls
      if (filters) {
        if (Array.isArray(filters)) {
          count = count + filters.length;
        } else {
          count++;
        }
      }
    });

    getFiltersSelected(count);
  }, [filterValues]);

  // preload filterValues
  useEffect(() => {
    if (filtersToSubmit) {
      setFilterValues(prev => ({ ...prev, ...filtersToSubmit }));
    }
  }, [filtersToSubmit]);

  return (
    <>
      <Badge
        className={classes.container}
        badgeContent={numberOfFiltersSelected}
        color="primary"
        slotProps={{
          badge: {
            'data-test-id': 'offers-filter-count',
          },
        }}
      >
        <StyledButton
          className={classes.filtersButton}
          variant="outlined"
          data-test-id={'offers-filter-button'}
          startIcon={<FilterListIcon color="primary" />}
          onClick={toggleModal}
        >
          Filter
        </StyledButton>
      </Badge>
      <FiltersModal
        setSelectedFiltersToSubmit={setSelectedFiltersToSubmit}
        filterValues={filterValues || {}}
        numberOfFiltersSelected={numberOfFiltersSelected}
        updateFiltersSelection={updateFiltersSelection}
        updateFiltersDateSelection={updateFiltersDateSelection}
        isOpen={isOpen}
        projectId={projectId}
        handleToggle={toggleModal}
        submitAllFilters={submitAllFilters}
        clearFilters={clearFilters}
        setFilterValues={setFilterValues}
      />
    </>
  );
};

export default Filters;
