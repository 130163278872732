import React from 'react';
import { withStyles } from '@mui/styles';
import { Tooltip } from '@mui/material';

import format from 'common/oldJavascripts/utils/format.js';

import ExpandableTableRow from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableRow';
import ExpandableTableCellTitle from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCellTitle';
import ExpandableTableCell from 'common/oldJavascripts/components/Base/ExpandableTable/ExpandableTableCell';
import ContactLink from 'common/oldJavascripts/components/Base/ContactLink';
import OfferI9Status from 'studio/components/OfferI9Status';

import I9VerificationButton from './I9VerificationButton';

const styles = {};

const I9VerificationRow = ({ projectId, offer, classes, allowEndDate }) => {
  const {
    id,
    accountCode,
    startDate,
    endDate,
    status,
    i9Document,
    keepI9,
    i9Verified,
    i9CreatedDate,
    badges,
    projectUser: { profile } = {},
    termsOfHire: {
      union: { description: unionDescription } = {},
      occupation: { description: occupationDescription } = {},
      department: { name: departmentName } = {},
    } = {},
  } = offer || {};
  const { email, firstName, middleName, lastName, phone } = profile || {};

  const fullName = format.fullName(firstName, middleName, lastName);
  const i9StatusProps = {
    start_date: startDate,
    status,
    i9_document: i9Document,
    keepI9,
    i9_verified: i9Verified,
    i9_created_date: i9CreatedDate,
    i9Correction: badges?.includes('i9_correction_requested'),
  };

  return (
    <ExpandableTableRow
      key={`item-${id || `index-${fullName}`}`}
      data-test-id={`Content-row-${id}`}
    >
      <ExpandableTableCell>
        <ExpandableTableCellTitle>{fullName}</ExpandableTableCellTitle>
        <Tooltip disableInteractive title={email} enterDelay={100}>
          <div className={classes.overflowText}>{email}</div>
        </Tooltip>
      </ExpandableTableCell>
      <ExpandableTableCell compact={true}>
        <ContactLink phone={phone} className={classes.contact} />
      </ExpandableTableCell>
      <ExpandableTableCell>{departmentName}</ExpandableTableCell>
      <ExpandableTableCell>
        {occupationDescription}
        <br />
        {accountCode}
      </ExpandableTableCell>
      <ExpandableTableCell>{unionDescription}</ExpandableTableCell>
      <ExpandableTableCell>{format.date(startDate)}</ExpandableTableCell>
      {allowEndDate && (
        <ExpandableTableCell>{format.date(endDate)}</ExpandableTableCell>
      )}
      <ExpandableTableCell>
        <OfferI9Status props={i9StatusProps} />
      </ExpandableTableCell>
      <ExpandableTableCell className={classes.noHorizontalPaddin}>
        <I9VerificationButton projectId={projectId} offer={offer} />
      </ExpandableTableCell>
    </ExpandableTableRow>
  );
};

export default withStyles(styles)(I9VerificationRow);
